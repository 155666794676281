@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:300,400,500&display=swap&subset=cyrillic';

body {
  margin: 0;
}

body, input {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
}


@media print {
  body {
    font-size: 20px;
  }
}

h1, h2, h3, h4, h5 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

a {
  color: black;
}

a:hover {
  color: #777;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
